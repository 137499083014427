<template>
  <div class="secondary px-1 pt-1 px-md-0">
    <div>
      <div class="px-0 px-md-5">
        <h1 class="my-auto info--text text-md-h3 text-xs-h4 py-md-5">
          <strong class="font-weight-medium primary--text">{{
            sizeArray
          }}</strong
          >&thinsp; customers
        </h1>
      </div>
    </div>
    <v-card elevation="0">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          placeholder="Search by complex id, Card number,..."
          max-width="300"
          class="mx-auto"
          clearable
        ></v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <div class="d-flex justify-space-around mx-auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="getAllFromServer(needAnonym)"
                
                text
                class="mx-auto my-auto info"
                v-bind="attrs"
                elevation="0"
                v-on="on"
              >
                refresh
                <v-icon> mdi-refresh </v-icon>
              </v-btn>
            </template>
            <span
              >Download data from our web-service to your local storage</span
            >
          </v-tooltip>
          <v-switch
            v-model="needAnonym"
            label="Need Anonym"
            class="ml-4"
          ></v-switch>
        </div>
      </v-card-title>
      <v-data-table
        :headers="
          needAnonym === false ? headersWithoutAnonym : headersWithAnonym
        "
        :items="loading === false ? arrayOfCustomers : []"
        :search="search"
        :loading="loading"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [20, 30, 40, 50, 75, 100],
        }"
        loading-text="Loading... Please wait"
        class="pa-0"
        :custom-filter="filterCustomer"
      >
        <template v-slot:item.complexId v-if="false">
          <div class="d-none"></div>
        </template>
        <template v-slot:item.isAnonym="{ item }" v-if="!loading">
          <v-icon class="my-auto mx-auto" v-if="item.isAnonym === true">
            mdi-incognito
          </v-icon>
        </template>
        <template v-slot:item.show="{ item }" v-if="!loading">
          <v-btn
            class="my-auto mx-auto px-2 py-1 secondary"
            text
            elevation="0"
            @click="getOneByComplexId(item)"
          >
            <v-icon class="my-auto mx-auto  primary--text">
              mdi-eye
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { getAllFromServer } from "@/assets/axios/GetAllCustomer.js";
export default {
  data() {
    return {
      headers: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
          class: "primary secondary--text font-weight-medium text-h6 mx-auto",
        },
        {
          text: "First name",
          value: "firstName",
          class: "primary secondary--text font-weight-medium text-h6 mx-auto",
        },
        {
          text: "Last name",
          value: "lastName",
          class: "primary secondary--text font-weight-medium text-h6 mx-auto",
        },
        {
          text: "E-mail",
          value: "email",
          class: "primary secondary--text font-weight-medium text-h6 mx-auto",
        },
        /*{
          text: "complex id",
          value: "complexId",
          align: " d-none",
          class: "d-none",
        },*/
      ],
      needAnonym: false,
      loading: true,
      updated: false,
      search: "",
      showInHeader: {
        text: "Show",
        value: "show",
        class: "primary secondary--text font-weight-medium text-h6",
      },
    };
  },
  methods: {
    getAllFromServer,
    getOneByComplexId(item) {
      this.$router.push("/customerProfile/" + item.complexId);
    },
    filterCustomer(value, search, item) {
      if (typeof value === "string") {
        return (
          value.toLowerCase().includes(search.toLowerCase()) ||
          item.complexId.includes(search) ||
          item.cardNumber?.includes(search) ||
          item.hashPan?.includes(search) ||
          item.phoneNumber?.includes(search)
        );
      }
      return value == search;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$store.state.allCustomers[0] === undefined) {
        if (localStorage.allCustomers !== undefined) {
          this.$store.commit(
            "setAllCustomers",
            JSON.parse(localStorage.allCustomers)
          );
        } else {
          this.getAllFromServer(this.needAnonym);
        }
        if (!localStorage.allCustomersWithAnonym) {
          this.getAllFromServer(this.needAnonym);
        }
      }
      this.loading = false;
    }, 800);
  },
  computed: {
    arrayOfCustomers() {
      return this.$store.state.allCustomers;
    },
    sizeArray() {
      return this.arrayOfCustomers.length;
    },
    headersWithAnonym() {
      return [
        ...this.headers,
        {
          text: "Anonym",
          value: "isAnonym",
          class: "primary secondary--text font-weight-medium text-h6",
        },
        this.showInHeader,
      ];
    },
    headersWithoutAnonym() {
      return [...this.headers, this.showInHeader];
    },
  },
  watch: {
    needAnonym: function () {
      if (this.needAnonym===true) {
        this.$store.commit(
          "setAllCustomers",
          JSON.parse(localStorage.allCustomersWithAnonym)
        );
      } else {
        this.$store.commit(
          "setAllCustomers",
          JSON.parse(localStorage.allCustomers)
        );
      }
    },
  },
};
</script>
<style scoped>
@media all and (max-width: 480px) {
  /deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    padding: 0px !important;
  }
  /deep/ .v-data-table__mobile-row__header {
    padding-right: 0px !important;
  }
}
.listTransition-enter-active,
.listTransition-leave-active {
  transition: all 0.3s;
  position: relative;
}
.listTransition-enter {
  opacity: 0;
  left: 500px;
}
.listTransition-enter-to {
  opacity: 1;
  left: 00px;
}
</style>
