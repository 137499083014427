var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"secondary px-1 pt-1 px-md-0"},[_c('div',[_c('div',{staticClass:"px-0 px-md-5"},[_c('h1',{staticClass:"my-auto info--text text-md-h3 text-xs-h4 py-md-5"},[_c('strong',{staticClass:"font-weight-medium primary--text"},[_vm._v(_vm._s(_vm.sizeArray))]),_vm._v(" customers ")])])]),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-text-field',{staticClass:"mx-auto",attrs:{"append-icon":"mdi-magnify","label":"Search","placeholder":"Search by complex id, Card number,...","max-width":"300","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),_c('div',{staticClass:"d-flex justify-space-around mx-auto"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-auto my-auto info",attrs:{"text":"","elevation":"0"},on:{"click":function($event){return _vm.getAllFromServer(_vm.needAnonym)}}},'v-btn',attrs,false),on),[_vm._v(" refresh "),_c('v-icon',[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Download data from our web-service to your local storage")])]),_c('v-switch',{staticClass:"ml-4",attrs:{"label":"Need Anonym"},model:{value:(_vm.needAnonym),callback:function ($$v) {_vm.needAnonym=$$v},expression:"needAnonym"}})],1)],1),_c('v-data-table',{staticClass:"pa-0",attrs:{"headers":_vm.needAnonym === false ? _vm.headersWithoutAnonym : _vm.headersWithAnonym,"items":_vm.loading === false ? _vm.arrayOfCustomers : [],"search":_vm.search,"loading":_vm.loading,"items-per-page":50,"footer-props":{
        itemsPerPageOptions: [20, 30, 40, 50, 75, 100],
      },"loading-text":"Loading... Please wait","custom-filter":_vm.filterCustomer},scopedSlots:_vm._u([(false)?{key:"item.complexId",fn:function(){return [_c('div',{staticClass:"d-none"})]},proxy:true}:null,(!_vm.loading)?{key:"item.isAnonym",fn:function(ref){
      var item = ref.item;
return [(item.isAnonym === true)?_c('v-icon',{staticClass:"my-auto mx-auto"},[_vm._v(" mdi-incognito ")]):_vm._e()]}}:null,(!_vm.loading)?{key:"item.show",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"my-auto mx-auto px-2 py-1 secondary",attrs:{"text":"","elevation":"0"},on:{"click":function($event){return _vm.getOneByComplexId(item)}}},[_c('v-icon',{staticClass:"my-auto mx-auto  primary--text"},[_vm._v(" mdi-eye ")])],1)]}}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }