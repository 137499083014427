import axios from 'axios'
export function getAllFromServer(needAnonym) {
  axios({
    url: this.$store.state.url + "/customer/get/all",
    params: { needAnonym },
    method: "GET",
  })
    .then((response) => {
      console.log(response.data)
      this.response = [...response.data];
      this.$store.commit("setAllCustomers", this.response);
      if (needAnonym) {
        localStorage.allCustomersWithAnonym = JSON.stringify(this.response);
      } else {
        localStorage.allCustomers = JSON.stringify(this.response);
      }
      this.$store.dispatch('snackbar/show',{
        message:"Data has been updated", type:"success", timeout:5});
    })
    .catch((error) => {
      console.log(error);
      this.$store.dispatch('snackbar/show',{
        message:error, type:"warning", timeout:5});
    })
  }